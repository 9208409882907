<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full">
      <div>
        <h2 class="mt-6 text-center leading-9 text-3xl font-normal text-gray-900">404... nothing here</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error404",
  components: {},
};
</script>